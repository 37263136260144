import { render, staticRenderFns } from "./czrefund.vue?vue&type=template&id=c01fa516&scoped=true&"
import script from "./czrefund.js?V=2021042011?vue&type=script&lang=js&"
export * from "./czrefund.js?V=2021042011?vue&type=script&lang=js&"
import style0 from "./vant.css?vue&type=style&index=0&id=c01fa516&scoped=true&lang=css&"
import style1 from "./czrefund.css?vue&type=style&index=1&id=c01fa516&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c01fa516",
  null
  
)

export default component.exports