import {
	mapState,
	mapActions
} from 'vuex'
import Vue from 'vue'
import {
	Popup,
	Toast,
	Dialog
} from 'vant'
import product from '../../utils/config.js'
import con from '../../utils/common.js'
const {
	Base64
} = require('js-base64')
import md5 from 'js-md5'
import get from '../../utils/request'

Vue.use(Toast)
export default {
	name: 'czrefund',
	components: {

	},
	data() {
		return {
			platOrderId: null,
			token: null,
			nextTip: '继续退款',
			loadingText: '加载订单状态...',
			loading: true,
			policyInfo: {},
			policyList: [],
		}
	},
	created() {

	},
	methods: {
		...mapActions('warranty', ['warDetail', 'refundOrder']),
		failFunc(){
			
		},
		nextStyle(){
			
		},
		successFunc(){
			
		},
		back() {

		},
	},
}
